import React, { useContext, useState, useEffect } from "react";
import RegistroContext from "../../contexto/registro/registroContext.js";
import PropTypes from "prop-types";
import AlertContext from "../../contexto/alerta/alertContext.js";
import Prompt from "../../assets/Prompt.js";

const Registrostd = ({
	registro: {
		registro: registro_id,
		habitacion,
		nombre,
		apellido,
		salida,
		tarifa,
		grupo,
		llegada,
		rsocial,
		registro,
	},
}) => {
	let fsalida = "sinfecha";
	const registroContext = useContext(RegistroContext);
	const alertContext = useContext(AlertContext);
	const [prompt, setPrompt] = useState(false);
	const [usr, setUsr] = useState("");
	const { setAlert } = alertContext;
	const {
		setModalOpenReg,
		traerRegistroSingle,
		borrarRegistro,
		traerRegistros,
		traerVaciasL,
		habitaciones,
		unSetRegistros,
		cambioHab,
		registros,
	} = registroContext;

	if (salida === null) {
		salida = "sin fecha";
	}
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const llegadaF = llegada.split("-");
	const fllegada =
		llegadaF[2] +
		"/" +
		meses[llegadaF[1].replace(/^0+/, "")] +
		"/" +
		llegadaF[0].slice(2);
	if (salida !== "sin fecha") {
		const salidaF = salida.split("-");
		fsalida =
			salidaF[2] +
			"/" +
			meses[salidaF[1].replace(/^0+/, "")] +
			"/" +
			salidaF[0].slice(2);
	}
	const [togg, setTogg] = useState(false);
	const [nHab, setnHab] = useState(false);
	const [checked, setChecked] = useState(false);

	////////////////////////////
	// onChangeNhab
	const onChangeNhab = (e) => {
		setnHab(e.target.value);
	};
	////////////////////////////
	// onChangeNhab
	const toggChecked = (e) => {
		setChecked(!checked);
		console.log(checked);
	};
	/////////////////////////
	// cambiarhabi
	const cambiarhabi = async (usr) => {
		console.log(1);
		if (usr !== null) {
			let body = {};
			body.rid = registro_id;
			body.habitacion = nHab;
			checked ? (body.status = 1) : (body.status = 0);
			const resp = await cambioHab(body);
			console.log(resp);
			if (resp.data.success) {
				setAlert(resp.data.messages[0], "success");
			} else {
				setAlert(resp.data.messages[0], "danger");
			}
		} else {
			setAlert("Operacion cancelada", "danger");
		}
		unSetRegistros();
	};
	///////////////////////////////////////////
	// cambioOnCLock
	const cambioOnClick = async (e) => {
		e.stopPropagation();
		togg ? setTogg(false) : setTogg(true);
	};
	const oCtraerRegistro = async (e) => {
		e.preventDefault();
		setModalOpenReg();
		traerRegistroSingle(registro_id);
	};
	//////////////////////////
	//useEffect
	useEffect(() => {
		habitaciones.length === 0 && traerVaciasL();
		registros.length === 0 && traerRegistros();
	}, [habitaciones, registros]);
	return (
		<>
			<tr id={registro_id} onClick={oCtraerRegistro} className='clickable'>
				<td>{habitacion}</td>
				<td>{apellido + " " + nombre}</td>
				<td>{rsocial}</td>
				<td>{fllegada}</td>
				<td>{fsalida}</td>
				<td>{tarifa}</td>
				<td>{grupo}</td>
				<td>
					<i
						id={registro_id}
						onClick={cambioOnClick}
						className='fas fa-sync-alt cambioh'
					></i>
				</td>
				<td>{registro}</td>
			</tr>
			{togg && (
				<tr>
					<td colSpan={2}>
						<h3>habitación Actual: {habitacion} Selecciones una Nueva:</h3>
					</td>
					<td>
						<input
							type='text'
							name='habitacion'
							list='hab'
							placeholder='Numero'
							size={11}
							onChange={(e) => onChangeNhab(e)}
							required
						/>
						<datalist id='hab'>
							{habitaciones.map((res, i) => {
								let n = i + 1;
								if (habitaciones[n - 1].habitacion % 100 === 32)
									return (
										<>
											<option key={i} value={res.habitacion}>
												{res.habitacion}-
											</option>
											<option>----</option>
										</>
									);
								else
									return (
										<option key={i} value={res.habitacion}>
											{res.habitacion}-
										</option>
									);
							})}
						</datalist>
					</td>
					<td colSpan={3}>
						<Prompt
							onClose={setPrompt}
							show={prompt}
							setKey={setUsr}
							onValidKey={cambiarhabi}
						/>
						<button
							className='btn btn-dark'
							onClick={() => {
								setPrompt(true);
							}}
						>
							Aplicar cambio
						</button>
					</td>
				</tr>
			)}
		</>
	);
};
Registrostd.propTypes = {
	registro: PropTypes.object.isRequired,
};
export default Registrostd;
