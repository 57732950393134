import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import PropTypes from "prop-types";

const Cuentastd = ({
	cuenta: {
		abonado,
		folio,
		nombre,
		apellido,
		rsocial,
		habitaciones,
		credito,
		cierre,
		subtotal,
		folios,
		apertura,
		aperturo,
		grupo,
		info,
		ref,
		maestra,
		salida,
		status,
		saldo,
	},
}) => {
	let habitacionesF = "";
	let tarifasF = "";
	habitaciones.map((row) => {
		if (status !== "abierta") {
			habitacionesF === ""
				? (habitacionesF += row.habitacion)
				: (habitacionesF += ", " + row.habitacion);
			tarifasF === "" ? (tarifasF += row.tarifa) : (tarifasF += ", " + row.tarifa);
		} else {
			if (row.status === 1) {
				habitacionesF === ""
					? (habitacionesF += row.habitacion)
					: (habitacionesF += ", " + row.habitacion);
				tarifasF === ""
					? (tarifasF += row.tarifa)
					: (tarifasF += ", " + row.tarifa);
			}
		}
	});
	console.log(folios);

	let esclava =
		habitacionesF === "0" ? "Esclava de la cuenta: " + maestra : null;

	////////////////////////////////////////
	// CONTEXT
	////////////////////////////////////////
	const cuentaContext = useContext(CuentaContext);
	const { traerCuenta, limpiarEsclavas } = cuentaContext;
	const navigate = useNavigate();

	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	const meses = [
		"dud",
		"Enero",
		"Feb",
		"Mar",
		"Abril",
		"Mayo",
		"Jun",
		"Jul",
		"Ago",
		"Sep",
		"Oct",
		"Nov",
		"Dic",
	];
	const oCtraerCuenta = async (e) => {
		e.preventDefault();
		limpiarEsclavas();
		const resp = await traerCuenta(folio);
		console.log(resp);

		if (resp.success) {
			navigate(`/cargos/${folio}`);
		}
	};

	return (
		<>
			<tr onClick={oCtraerCuenta} className='clickable'>
				<td>
					<strong>{folio}</strong>
				</td>
				<td>{apellido + " " + nombre}</td>
				<td>{rsocial}</td>
				<td>{grupo}</td>
				<td>{apertura.slice(5, 10)}</td>
				<td>
					{cierre.slice(5, 10) === "00-00" && salida !== null ? (
						<p style={{ color: "#999" }}>{salida.slice(5, 10)}</p>
					) : cierre !== null ? (
						<strong className='xbold'>{cierre.slice(5, 10)}</strong>
					) : null}
				</td>
				<td>{moneyForm(saldo) !== "Sin Cargos" ? moneyForm(saldo) : "0"}</td>
				<td>{aperturo}</td>
				<td className='wbreak' style={{ width: "15%" }}>
					<p>Folios:{folios}</p>
				</td>
			</tr>
			<tr onClick={oCtraerCuenta} className='clickable'>
				<td colSpan={5}>Observación: {info}</td>
				<td colSpan={4}>Referencia: {ref}</td>
			</tr>
			<tr onClick={oCtraerCuenta} className='clickable'>
				<td colSpan={4} style={{ textAlign: "left" }}>
					Habitaciones:{habitacionesF === "0" ? maestra : habitacionesF}
				</td>
				<td colSpan={5} style={{ textAlign: "left" }}>
					Tarifas: {tarifasF}
				</td>
			</tr>
		</>
	);
};
Cuentastd.propTypes = {
	cuenta: PropTypes.object.isRequired,
};
export default Cuentastd;
