const tarifas = [
	{
		IdTarifa: "9",
		Nombre: "SGLD1",
		Importe: "980",
	},
	{
		IdTarifa: "70",
		Nombre: "SPRO1",
		Importe: "931",
	},
	{
		IdTarifa: "1",
		Nombre: "S10D1",
		Importe: "882",
	},
	{
		IdTarifa: "3",
		Nombre: "S15D1",
		Importe: "833",
	},
	{
		IdTarifa: "92",
		Nombre: "UASD1",
		Importe: "784",
	},
	{
		IdTarifa: "10",
		Nombre: "DBLD2",
		Importe: "1020",
	},
	{
		IdTarifa: "71",
		Nombre: "DPRO2",
		Importe: "969",
	},
	{
		IdTarifa: "15",
		Nombre: "D10D2",
		Importe: "918",
	},
	{
		IdTarifa: "16",
		Nombre: "D15D2",
		Importe: "867",
	},
	{
		IdTarifa: "93",
		Nombre: "UASD2",
		Importe: "816",
	},
	{
		IdTarifa: "11",
		Nombre: "TPLD3",
		Importe: "1060",
	},
	{
		IdTarifa: "72",
		Nombre: "TPRO3",
		Importe: "1007",
	},
	{
		IdTarifa: "21",
		Nombre: "T10D3",
		Importe: "954",
	},
	{
		IdTarifa: "22",
		Nombre: "T15D3",
		Importe: "901",
	},
	{
		IdTarifa: "94",
		Nombre: "UASD3",
		Importe: "848",
	},
	{
		IdTarifa: "12",
		Nombre: "CPLD4",
		Importe: "1100",
	},
	{
		IdTarifa: "73",
		Nombre: "CPRO4",
		Importe: "1045",
	},
	{
		IdTarifa: "27",
		Nombre: "C10D4",
		Importe: "990",
	},
	{
		IdTarifa: "28",
		Nombre: "C15D4",
		Importe: "935",
	},
	{
		IdTarifa: "95",
		Nombre: "UASD4",
		Importe: "880",
	},
	{
		IdTarifa: "36",
		Nombre: "NOCD1",
		Importe: "0",
	},
	{
		IdTarifa: "361",
		Nombre: "NOCD2",
		Importe: "0",
	},
	{
		IdTarifa: "362",
		Nombre: "NOCD3",
		Importe: "0",
	},
	{
		IdTarifa: "363",
		Nombre: "NOCD4",
		Importe: "0",
	},
	{
		IdTarifa: "102",
		Nombre: "EXPD1",
		Importe: "788",
	},
	{
		IdTarifa: "103",
		Nombre: "EXPD2",
		Importe: "820",
	},
	{
		IdTarifa: "104",
		Nombre: "EXPD3",
		Importe: "853",
	},
	{
		IdTarifa: "105",
		Nombre: "EXPD4",
		Importe: "884",
	},
];
export default tarifas;
