import React from "react";

const Reportetdf = ({
    factura: {
        id,
        serie,
        folio,
        c_id,
        e_id,
        forma_pago,
        importe,
        iva,
        ieh,
        total,
        fecha,
        folio_fiscal,
        usr,
        rfc,
        rsocial,
        propina
    }
}) => {

    ////////////////////////////////
    //METODOS
    const convertirTimestamp = (timestamp) => {
        if(timestamp){
            timestamp = timestamp.split(" ");
            const hora=timestamp[1].slice(0,5);
            timestamp = timestamp[0].split("-");
            const meses = [
                "dud", "Enero", "Feb",
                "Mar", "Abril", "Mayo",
                "Jun", "Jul", "Ago",
                "Sep", "Oct", "Nov", "Dic",
            ];
            const fecha= timestamp[2] + "/" + 
                meses[timestamp[1].replace(/^0+/,"")] + "/" +timestamp[0].substring(2,4);
            return [hora, fecha];
        }
        else
            return [null,null];
    };
    const gtotal = parseFloat(propina) + parseFloat(total);
    const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "$"+ propina;
		}
	};
    
    const [hora, fechaf] = convertirTimestamp(fecha);
    return(
        <tr>
            <td>{folio}</td>
            <td>{c_id}</td>
            <td>{fechaf}</td>
            <td>{rfc}</td>
            <td>{rsocial}</td>
            <td>{moneyForm(importe)}</td>
            <td>{moneyForm(iva)}</td>
            <td>{moneyForm(total)}</td>
            <td>{moneyForm(propina)}</td>
            <td>{moneyForm(gtotal)}</td>
            <td>{forma_pago}</td>
        </tr>
    )
}
export default Reportetdf;