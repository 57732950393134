import React, { useContext, useEffect, useState } from "react";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Prompt from "../../assets/Prompt";

const Folios = (props) => {
	////////////////////////////////////////////////
	const cuentaContext = useContext(CuentaContext);
	const { traerEsclavas, folios, traerCuenta, nuevoFolio, cuenta } =
		cuentaContext;
	const { maestra } = cuenta;
	/////////////////////
	// select esclava
	const selectEsclava = (e) => {
		if (e !== 0) {
			traerCuenta(e.target.value);
		}
	};

	///////////////////////////////////////
	const [usr, setUsr] = useState("");
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};

	/////////////////////
	/// añadir folio
	const addFolio = async (usr) => {
		const resp = await nuevoFolio(props.id);
	};

	/////////////////////////////////////////////
	// use Effect
	useEffect(() => {
		if (folios.length === 0) {
			traerEsclavas(props.id);
		}
	}, [folios]);

	return (
		<div className='grid-3 w-100' style={{ gridGap: "5px" }}>
			<p className='gspan-3'>Folios de la cuenta:</p>
			{Array.isArray(folios) ? (
				<div className='gspan-2'>
					<select name='folios' id='' onChange={selectEsclava}>
						<option key={0} value={0}>
							Seleccione un folio
						</option>
						{folios.map((row) => {
							return (
								<option key={row.folio} value={row.folio}>
									{row.folio}
								</option>
							);
						})}
					</select>
				</div>
			) : (
				<div className='gspan-2'></div>
			)}
			{maestra === null && (
				<button className='btn btn-dark floatRight' onClick={prompteador}>
					Folio+
				</button>
			)}
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={addFolio}
			/>
		</div>
	);
};

export default Folios;
