import React, { useContext, useEffect, useState } from "react";
import Spinner from "../elementos/Spinner";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cuentastd from "./Cuentastd";
import Alertcontext from "../../contexto/alerta/alertContext";
const Cuentasth = () => {
	////////////////////
	//contexto
	const cuentaContext = useContext(CuentaContext);
	const alertContext = useContext(Alertcontext);
	const { traerCuentasAct, traerCuentas, activas } = cuentaContext;
	const { setAlert } = alertContext;
	/////////////////////////////////////
	// formateador de fecha
	const ffrm = (fecha) => {
		let infRw = true;
		let inf = "";
		if (typeof fecha === "undefined") {
			infRw = new Date();
			inf = infRw.toLocaleDateString("es-MX", {
				year: "numeric",
				month: "long",
				day: "2-digit",
			});
		} else {
			let fechArr = fecha.split("-");
			console.log(fechArr[1]);
			infRw = new Date(fechArr[0], fechArr[1] - 1);
			inf = infRw.toLocaleDateString("es-MX", {
				year: "numeric",
				month: "long",
			});
		}
		return inf;
	};
	let tz = new Date(Date.now()).getTimezoneOffset() * 60000;
	const hoy = new Date(Date.now() - tz).toISOString().slice(0, 7);
	const [fact, setFact] = useState(hoy);
	////////////////////////////////////
	//Filtrado

	//////////////////////////////
	// States
	const [filset, setFilset] = useState(activas);
	const [room, setRoom] = useState("");
	const [apellido, setApellido] = useState("");
	const [empresa, setEmpresa] = useState("");
	const [grupo, setGrupo] = useState("");
	const [apertura, setApertura] = useState("");
	const [salida, setSalida] = useState("");
	const [resno, setResno] = useState("");
	const [ldng, setLdng] = useState(false);

	const resetter = (exclu) => {
		const inarr = [
			"room",
			"apellido",
			"empresa",
			"grupo",
			"apertura",
			"salida",
			"resno",
		];
		let filarr = inarr.filter((r) => {
			return r !== exclu;
		});
		filarr.map((r) => {
			r === "room" && setRoom("");
			r === "apellido" && setApellido("");
			r === "empresa" && setEmpresa("");
			r === "grupo" && setGrupo("");
			r === "apertura" && setApertura("");
			r === "salida" && setSalida("");
			r === "resno" && setResno("");
		});
	};

	const onChangeRoom = async (e) => {
		setRoom(e.target.value);
		resetter("room");
		if (activas.length !== 0) {
			let filtrado = activas.filter((row) =>
				row.habs.includes(parseInt(e.target.value))
			);
			setFilset(filtrado);
			if (e.target.value.length > 2 && filtrado.length === 0) {
				setAlert("no se encontraron cuentas con esos parámetros", "danger");
				setFilset(activas);
			}
		}
		if (e.target.value == "") {
			setAlert("");
			setFilset(activas);
		}
	};

	const onChangeApellido = async (e) => {
		setApellido(e.target.value);
		resetter("apellido");
		setLdng(true);
		if (activas.length !== 0) {
			let filtrado = await activas.filter((row) => {
				let paterno = row.apellido.split(" ");
				return paterno[0].toLowerCase().includes(e.target.value.toLowerCase());
			});
			if (filtrado.length !== 0) {
				setFilset(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				setFilset(activas);
			}
			setTimeout(() => {
				setLdng(false);
			}, 500);
		}
	};

	const onChangeEmpresa = async (e) => {
		setEmpresa(e.target.value);
		resetter("empresa");
		setLdng(true);
		if (activas.length !== 0) {
			let filtrado = await activas.filter((row) => {
				if (row.rsocial !== null) {
					return row.rsocial.toLowerCase().includes(e.target.value.toLowerCase());
				}
			});
			if (filtrado.length !== 0) {
				setFilset(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				setFilset(activas);
			}
			setTimeout(() => {
				setLdng(false);
			}, 500);
		}
	};
	const onChangeResno = (e) => {
		setResno(e.target.value);
	};
	const onChangeGrupo = async (e) => {
		setGrupo(e.target.value);
		resetter("grupo");
		setLdng(true);
		if (activas.length !== 0) {
			let filtrado = await activas.filter((row) => {
				if (row.grupo !== null) {
					return row.grupo.toLowerCase().includes(e.target.value.toLowerCase());
				}
			});
			if (filtrado.length !== 0) {
				setFilset(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				setFilset(activas);
			}
			setTimeout(() => {
				setLdng(false);
			}, 500);
		}
	};
	const onChangeApertura = async (e) => {
		setApertura(e.target.value);
		resetter("apertura");
		setLdng(true);
		if (activas.length !== 0) {
			let filtrado = await activas.filter((row) => {
				if (row.apertura !== null) {
					return row.apertura.substring(0, 10) === e.target.value;
				}
			});
			if (filtrado.length !== 0) {
				setFilset(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				setFilset(activas);
			}
			setTimeout(() => {
				setLdng(false);
			}, 500);
		}
	};
	const onChangeSalida = async (e) => {
		setSalida(e.target.value);
		resetter("salida");
		setLdng(true);
		if (activas.length !== 0) {
			let filtrado = await activas.filter((row) => {
				if (row.apertura !== null) {
					return row.cierre.substring(0, 10) === e.target.value;
				}
			});
			if (filtrado.length !== 0) {
				setFilset(filtrado);
			} else {
				setAlert("No se encontraron coincidencias", "danger");
				setFilset(activas);
			}
			setTimeout(() => {
				setLdng(false);
			}, 500);
		}
	};

	/////////////////////
	///UseEffect
	useEffect(() => {
		if (activas.length === 0) {
			const resp = traerCuentasAct();
			resp.then((r) => {
				setFilset(r.data);
				console.log(activas);
			});
		}
	}, []);
	return (
		<>
			<section className='all-center-row'>
				<h1 style={{ fontSize: "2rem" }}>Cuentas activas</h1>
			</section>
			<section className='grid-4 noprint my'>
				<article>
					<label htmlFor='hab'>
						<p className='medium'>Por Habitación</p>
					</label>
					<input type='text' name='hab' value={room} onChange={onChangeRoom} />
					<label htmlFor='grupo'>
						<p className='medium'>Por Grupo</p>
					</label>
					<input type='text' name='grupo' value={grupo} onChange={onChangeGrupo} />
				</article>
				<article className='gspan-2'>
					<label htmlFor='apellido'>
						<p className='medium'>Por Apellido</p>
					</label>
					<input
						type='text'
						name='apellido'
						value={apellido}
						onChange={onChangeApellido}
					/>
					<label htmlFor='empresa'>
						<p className='medium'>Por Empresa</p>
					</label>
					<input
						type='text'
						name='empresa'
						value={empresa}
						onChange={onChangeEmpresa}
					/>
				</article>
				<article className=''>
					<label htmlFor='apertura'>
						<p className='medium'>Por Apertura</p>
					</label>
					<input
						type='date'
						name='apertura'
						value={apertura}
						onChange={onChangeApertura}
					/>
					<label htmlFor='salida'>
						<p className='medium'>Por Salida</p>
					</label>
					<input
						type='date'
						name='salida'
						value={salida}
						onChange={onChangeSalida}
					/>
				</article>
			</section>
			<div className=''>
				<table id='cuentas' className='w-100 printabla tfix'>
					<thead className='stickiehead'>
						<tr>
							<th>Cta.</th>
							<th style={{ width: "20%" }}>Huesped</th>
							<th style={{ width: "15%" }}>Empresa</th>
							<th>Grupo</th>
							<th>Llegada</th>
							<th>Salida</th>
							<th>Saldo</th>
							<th>Reg</th>
							<th style={{ width: "15%" }}>Folios extra</th>
						</tr>
					</thead>
					<tbody>
						{filset.length !== 0 && ldng === false ? (
							filset.map((res) => <Cuentastd key={res.folio} cuenta={res} />)
						) : (
							<tr>
								<td colSpan={11}>
									<Spinner />
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Cuentasth;
