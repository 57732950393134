import React, { useState, useContext, useEffect } from "react";
import CargoContext from "../../contexto/cargo/cargoContext";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import conceptos from "../../assets/conceptos.js";
import metodosdp from "../../assets/metodosdp.js";
import AlertContext from "../../contexto/alerta/alertContext";
import Tarifas from "../../assets/tarifas";
import Prompt from "../../assets/Prompt";

const NuevoCargo = ({ setNewCargo }) => {
	////////////////////
	//contexto
	const cargoContext = useContext(CargoContext);
	const { nuevoCargo, cargos } = cargoContext;

	const cuentaContext = useContext(CuentaContext);
	const { cuenta, usetCuenta, traerCuenta } = cuentaContext;
	const tarifas = Tarifas;
	const habitaciones = cuenta.habitaciones;

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	///////////////////
	//state
	const [metodo, setMetodo] = useState("Efectivo");
	const [usr, setUsr] = useState(null);
	const dialogo = document.getElementById("diag");
	const [concepto, setConcepto] = useState("habitacion");
	const [hab, setHab] = useState(habitaciones[0].habitacion);
	const [tar, setTar] = useState(habitaciones[0].tarifa);
	const [importe, setImporte] = useState(0);
	///////////////////
	//onChange inputs
	const onChangeConcepto = (event) => {
		setConcepto(event.target.value);
	};

	const onChangePago = (event) => {
		setMetodo(event.target.value);
	};

	const onChangeHab = (event) => {
		setHab(event.target.value);
		let tar2f = event.target.value;
		let tar = habitaciones.find((hab) => hab.habitacion == tar2f);
		setTar(tar.tarifa);
	};
	const onChangeImporte = (event) => {
		setImporte(event.target.value);
	};
	const onChangeUsr = (e) => setUsr(e.target.value);

	///////////////////////////////////////
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	///////////////////////////////////
	//modal tog
	const modalUp = (e) => {
		e.preventDefault();
		dialogo.showModal();
	};
	///////////////////////////
	// truncadora
	function dosDecimales(n) {
		let t = n.toString();
		let regex = /(\d*.\d{0,2})/;
		return t.match(regex)[0];
	}
	////////////////////////////
	// Nuevo cargo para guardar
	const submitCargo = async (usr) => {
		if (cuenta.status !== "abierta") {
			setAlert("No puede hacer movimientos en esta cuenta", "danger");
		}
		//////se recibe el input///////////////
		let importeRaw = parseFloat(importe);
		let base = "";
		//como solo dan precios netos sacamos la base
		base = concepto === "habitacion" ? importeRaw / 1.19 : importeRaw / 1.16;
		let importef = 0;
		let ivar = 0;
		let iehr = 0;
		let iva = 0;
		let ieh = 0;
		//si es abobo pasa entero si es habitación se le hace el 3% adicional
		if (concepto === "abono" || concepto === "propina") {
			if (metodo === "Reembolso") {
				importef = importeRaw * -1;
			} else {
				importef = importeRaw;
			}
		} else if (concepto === "habitacion") {
			iva = base * 0.16;
			ieh = base * 0.03;
			importef = importeRaw - (iva + ieh);
		} else {
			iva = base * 0.16;
			importef = importeRaw - iva;
		}
		//construimod el cuerpo de la consulta
		const cargo = {
			habitacion: hab,
			cuenta_id: cuenta.cuenta,
			concepto: concepto,
			metodo_pago: concepto === "abono" ? metodo : null,
			importe: importef,
			iva: iva,
			ieh: ieh,
			referencia: referencia.value,
			comentario: comentario.value,
			cajero: usr,
		};

		///se envia la peticion para guardar el cargo
		const resp = await nuevoCargo(cargo);
		console.log(resp);

		if (resp.success) {
			setAlert("Movimiento generado con éxito", "success");
		} else {
			setAlert("Error: " + resp.data.messages, "danger");
		}
		traerCuenta(cuenta.cuenta);
		setUsr("");
	};

	////////////////////////////////////////////
	//CAMPO GENERICO
	const useField = ({ type, name }) => {
		const [value, setValue] = useState("");
		const onChange = (event) => {
			setValue(event.target.value);
		};
		return {
			type,
			value,
			name,
			onChange,
		};
	};

	const comentario = useField({ type: "text", name: "comentario" });
	const referencia = useField({ type: "text", name: "referencia" });

	//////////////////
	//useEffect
	useEffect(() => {
		let importarifa = 0;
		if (concepto === "habitacion") {
			if (tarifas.length > 0) {
				tarifas.map((row, i) => {
					if (row.Nombre === tar) {
						const importeHab = parseFloat(row.Importe);
						importarifa += parseFloat(row.Importe);
					}
				});
			}
			setImporte(importarifa);
			if (cuenta.status !== "abierta") {
				setNewCargo(false);
			}
		} else {
			setImporte(0);
		}
		//eslint-disable-next-line
	}, [concepto, tar]);
	return (
		<>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={submitCargo}
			/>
			<div className='w-100 my-1 dcard'>
				<h2>Añadir Cargo</h2>
				<form action='' onSubmit={prompteador} className='grid-4'>
					<div>
						{/*Metodo de Pago*/}
						<label htmlFor='concepto'>Habitacion</label>
						<select name='select' onChange={onChangeHab} required>
							{habitaciones.map((row, i) => (
								<option key={i} value={row.habitacion}>
									{row.habitacion}
								</option>
							))}
							<option value='caja'>Caja</option>
						</select>
					</div>
					<div>
						{/*Metodo de Pago*/}
						<label htmlFor='concepto'>concepto</label>
						<select
							name='select'
							onChange={onChangeConcepto}
							value={concepto}
							required
						>
							{conceptos.map((concepto, i) => (
								<option key={i} value={concepto.value}>
									{concepto.nombre}
								</option>
							))}
						</select>
					</div>
					<div>
						{/*Metodo de Pago*/}
						<label htmlFor='metodopago'>Metodo de Pago</label>
						<select
							name='select'
							onChange={onChangePago}
							value={metodo}
							disabled={concepto === "abono" ? false : true}
						>
							{metodosdp.map((metodo, i) => (
								<option key={i} value={metodo.value}>
									{metodo.nombre}
								</option>
							))}
						</select>
					</div>
					<div>
						{/*importe*/}
						<label htmlFor='importe'>Importe</label>
						<input
							value={importe}
							type='number'
							className=''
							onChange={onChangeImporte}
							required
						/>
					</div>

					<div>
						{/*Referencia*/}
						<label htmlFor='referencia'>Referencia</label>
						<input {...referencia} className='' />
					</div>

					<div className='gspan-2'>
						{/*Comentario*/}
						<label htmlFor='comentario'>Comentario</label>
						<input {...comentario} className='' />
					</div>
					<button type='submit' className='btn btn-dark btn-block'>
						Agregar Cargo
					</button>
				</form>
			</div>
		</>
	);
};

export default NuevoCargo;
