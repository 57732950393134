import React from "react";
import Inireporeserva from "../reservaciones/Inireporeserva";
const Inicio = () => {
	return (
		<>
			<Inireporeserva />
		</>
	);
};

export default Inicio;
