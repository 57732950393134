import React from "react";

const Repofactmestd = ({
	factura: {
		id,
		serie,
		folio,
		c_id,
		e_id,
		forma_pago,
		importe,
		iva,
		ieh,
		total,
		fecha,
		usr,
		folio_fiscal,
		rfc,
		rsocial,
		conceptos,
	},
}) => {
	let xfecha = "";
	if (fecha !== "") {
		const fechaS = fecha.split(" ");
		const llegadaF = fechaS[0].split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		xfecha =
			llegadaF[2] +
			"/" +
			meses[llegadaF[1].replace(/^0+/, "")] +
			"/" +
			llegadaF[0].substring(2, 4);
	}
	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	let hab = 0;
	let caf = 0;
	let lav = 0;
	let bar = 0;
	let prop = 0;
	let cneg = 0;
	let cod = 0;
	conceptos.map((row) => {
		if (row.status === 0) {
			switch (row.concepto) {
				case "habitacion":
					hab = +hab + +row.importe;
					break;
				case "cafeteria":
					caf = +caf + +row.importe;
					break;
				case "Consumo":
					caf = +caf + +row.importe;
					break;
				case "lavanderia":
					lav = +lav + +row.importe;
					break;
				case "bar":
					bar = +bar + +row.importe;
					break;
				case "propina":
					prop = +prop + +row.importe;
					break;
				case "CentroNegocios":
					cneg = +cneg + +row.importe;
					break;
				case "cod":
					cod = +cod + +row.importe;
					break;
			}
		}
	});
	return (
		<tr>
			<td>
				<strong>{serie + folio}</strong>
			</td>
			<td>
				<strong>{rfc}</strong>
			</td>
			<td>
				<strong>{rsocial}</strong>
			</td>
			<td>{xfecha}</td>
			<td>{moneyForm(hab)}</td>
			<td>{moneyForm(caf)}</td>
			<td>{moneyForm(lav)}</td>
			<td>{moneyForm(bar)}</td>
			<td>{moneyForm(prop)}</td>
			<td>{moneyForm(cneg)}</td>
			<td>{moneyForm(importe)}</td>
			<td>{moneyForm(iva)}</td>
			<td>{moneyForm(ieh)}</td>
			<td>{moneyForm(+importe + +iva + +ieh)}</td>
		</tr>
	);
};

export default Repofactmestd;
