import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CuentaContext from "../../contexto/cuenta/cuentaContext";
import Cargostd from "./Cargostd";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext";
import NuevoCargo from "./NuevoCargo";
import BuscCargosByHab from "./BuscCargosByHab.js";
import AlertContext from "../../contexto/alerta/alertContext";
import Separacion from "../cuentas/Separacion";
import Descfactd from "../facturas/Descfactd";
import Estadodecuenta from "../facturas/Estadodecuenta.jsx";
import ReactToPrint from "react-to-print";
import Modal from "../../assets/Modal";
import Facturar from "../facturas/Facturar4";
import Folios from "./Folios.js";
import Habplus from "./Habplus";
import Setempresa from "./Setempresa.js";

const Cargosth = (props) => {
	///////////////////////////////////////////////
	// CONTEXT
	const registroContext = useContext(RegistroContext);
	const { habitaciones: habis, traerVaciasL } = registroContext;
	const facturacionContext = useContext(FacturacionContext);
	const { traerFacturaByCuenta, facturas } = facturacionContext;
	const cuentaContext = useContext(CuentaContext);
	const {
		cuenta,
		traerCuenta,
		cancelarCuentaById,
		traerEsclavas,
		cuentaPatchByFolio,
		folios,
	} = cuentaContext;

	const ref2p = React.createRef();
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//////////////////////////////////////////////////////
	// base state
	const [newCargo, setNewCargo] = useState(false);
	const { folio } = useParams();
	const [cuenta_id, setCuenta_id] = useState(folio);
	const [modal, setModal] = useState(false);
	const [separarBody, setSepararBody] = useState({});
	const [cancelable, setCancelable] = useState(false);
	const [facMod, setFactMod] = useState(false);
	const {
		cuenta: id,
		apellido,
		nombre,
		salida,
		habitaciones,
		conceptos,
		subtotal,
		impuestos,
		abonado,
		status,
		info,
		ref,
		grupo,
		rsocial,
		credito,
	} = cuenta;
	if (folio === 0) {
		setCuenta_id(id);
	}
	console.log(habitaciones);

	//////////////////////////////////////////////
	//valores a numero
	const subtotalf = parseFloat(subtotal);
	const impuestosf = parseFloat(impuestos);
	const abonadof = parseFloat(abonado);
	const subneto = parseFloat(subtotalf) + parseFloat(impuestosf);
	const saldo = parseFloat(subneto) - parseFloat(abonadof);
	///inicializamos titulos
	let habiArr = [];
	let fsalida = "";
	let habitacionesF = "";
	let TarifasF = "";
	let habtit;
	let tartit;
	let habsAct;

	///////////////////////////////////////////
	//si hay cuenta poblamos los titulos acorde
	if (typeof habitaciones !== "undefined" && Object.keys(cuenta).length > 0) {
		habitaciones.map((row) => {
			if (status !== "abierta") {
				habitacionesF === ""
					? (habitacionesF += row.habitacion)
					: (habitacionesF += ", " + row.habitacion);

				TarifasF === ""
					? (TarifasF += row.tarifa)
					: (TarifasF += ", " + row.tarifa);
			} else {
				if (row.status === 1) {
					habitacionesF === ""
						? (habitacionesF += row.habitacion)
						: (habitacionesF += ", " + row.habitacion);

					TarifasF === ""
						? (TarifasF += row.tarifa)
						: (TarifasF += ", " + row.tarifa);
				}
			}
		});
		if (habitaciones.length > 1) {
			habtit = "Habitaciones ";
			tartit = "Tarifas ";
		} else {
			habtit = "Habitación ";
			tartit = "Tarifa ";
		}
		///////////////////////////////////////
		//formateo de fechas
		const fechaS = salida.split(" ");
		const salidaF = fechaS[0].split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		fsalida =
			salidaF[2] + "/" + meses[salidaF[1].replace(/^0+/, "")] + "/" + salidaF[0];

		///////////////////////////////////////
		// aislamso habitaciones actuales
		habsAct = habitaciones.filter((row) => {
			if (row.status === 1) {
				return true;
			}
		});
		habiArr = habsAct.map((r) => {
			return r.habitacion;
		});
	}
	////nuevo cargo togg controla el despliegue del formulario
	const toggleNewCargo = () => {
		if (newCargo) {
			setNewCargo(false);
		} else {
			setNewCargo(true);
		}
	};

	///////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + Math.round(numero * 100) / 100;
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	//////////////////////////////
	//Cerrar Cuenta
	const cerrarCuenta = async (e) => {
		e.preventDefault();
		setFactMod(true);
	};

	///////////////////////////
	// cancelar cuenta
	const cancelarFac = async (e) => {
		e.preventDefault();
		const cerro = prompt(
			`Esta a punto de cerrar la cuenta para confirmar ingrese su clave`
		);
		if (cerro !== null) {
			const resp = await cancelarCuentaById(id, cerro);
			if (resp.success) {
				setAlert("La cuenta ha sido cancelada", "success");
				setCancelable(false);
				traerCuenta(id);
			} else {
				setAlert(resp.data.messages, "danger");
			}
		} else {
			setAlert("Operación cancelada", "danger");
		}
	};

	//////////////////////
	//separar
	const sepaload = async () => {
		const resp = await traerCuenta(id);
		const resp2 = await traerEsclavas(id);
		if (
			resp.success &&
			resp.data.conceptos !== "Cuenta sin cargos" &&
			Array.isArray(resp2)
		) {
			setSepararBody(resp.data);
			setModal(true);
		} else {
			setAlert("Cuenta sin Cargos o folios que separar", "danger");
		}
	};

	////////////////////
	const crediTogg = () => {
		let bod = { folio: id, credito: 0 };
		let bod2 = { folio: id, credito: 1000 };
		credito > 0 && status === "abierta"
			? cuentaPatchByFolio(bod)
			: cuentaPatchByFolio(bod2);
		traerCuenta(id);
	};

	////////////////////
	const drill = async (status) => {
		traerCuenta(folio);
		setFactMod(status);
	};
	/////////////////////////////////////////////
	// use Effect
	useEffect(() => {
		if (Object.keys(cuenta).length === 0) {
			traerCuenta(cuenta_id);
		}
		if (Object.keys(habis).length === 0) {
			traerVaciasL();
		}
		if (folios.length === 0) {
			traerEsclavas(id);
		}
		if (typeof folio === "undefined" && Object.keys(cuenta).length !== 0) {
			setCuenta_id(cuenta);
		}
		if (Array.isArray(conceptos)) {
			const conceptosfiltrados = conceptos.filter((r) => r.status === 0);
			if (conceptosfiltrados.length === 0 && status === "abierta") {
				setCancelable(true);
			}
			let abosum = conceptos.reduce((a, row) => a + parseFloat(row.abono), 0);
			abosum < 1 && setCancelable(true);
		}
		if (Object.keys(cuenta).length !== 0) {
			traerFacturaByCuenta(id);
		}
	}, [cuenta, conceptos, cancelable]);

	////////////////////////////////////////////
	//Return data apartir de aqui es jsx
	return (
		<>
			{<BuscCargosByHab />}
			{/* Busqueda por habitacion*/}

			<div id='cargosMainWrapp' className='xmodalOver relative'>
				{/*inicia cabezal*/}
				<h1>
					{apellido + " " + nombre}
					{status === "abierta" ? (
						<>
							<span className='floatRight'>
								{newCargo ? (
									<i className='fas fa-minus-square' onClick={toggleNewCargo}></i>
								) : (
									<i className='fas fa-plus-square' onClick={toggleNewCargo}></i>
								)}
							</span>
						</>
					) : (
						<span className='large floatRight' style={{ color: "red" }}>
							{status}
						</span>
					)}
					<span className='lead' style={{ fontWeight: "100" }}>
						Cuenta: <strong>{id}</strong>
					</span>
				</h1>
				{/*Finaliza Cabezal*/}

				<div id='cuentaData' className='w-100'>
					<div>
						<div className='grid-4 w-100' style={{ gridGap: "10px" }}>
							<div className='gspan-2'>
								<p className=''>
									{habtit}: <strong>{habitacionesF}</strong>
								</p>
								<p>
									{tartit}:{" "}
									<strong
										className='small'
										style={{ fontFamily: "sans-serif", fontStretch: "extra-condensed" }}
									>
										{TarifasF}
									</strong>
								</p>
								<p>
									Observaciones: <strong>{info}</strong>
								</p>
								<p>
									Referencia: <strong>{ref}</strong>
								</p>
							</div>
							<div className=''>
								<p>
									Salida:
									<strong> {fsalida}</strong>
								</p>
								<p>
									Grupo:
									<strong> {grupo}</strong>
								</p>
								<p>Empresa:</p>
								<strong className={rsocial === "PUBLICO EN GENERAL" ? "tacha" : null}>
									{rsocial === null ? "Sin datos fiscales" : rsocial}
								</strong>
							</div>
							<div>
								<p>
									Saldo: <strong>{moneyForm(saldo)}</strong>
								</p>
								<p>
									Cargo a Habitación:{" "}
									<i
										className={`fas fa-utensils floatRight large ${
											credito > 0 ? "on" : "off"
										}`}
										style={{ marginTop: "-10px" }}
										onClick={crediTogg}
									></i>
								</p>
								<Folios id={id} />
							</div>
						</div>
						<div
							className={
								status === "abierta" && habitaciones.length > 1 ? "grid-6" : "grid-5"
							}
							style={{ marginTop: "10px" }}
						>
							<Habplus id={id} />
							<Setempresa id={id} />
							{status === "abierta" && habitaciones.length > 1 && (
								<button className='btn btn-dark' type='button' onClick={sepaload}>
									Separar Cuenta
								</button>
							)}
						</div>
					</div>

					<Separacion
						stado={modal}
						cuenta={separarBody}
						cerrarM={() => {
							setModal(false);
						}}
					/>
					{newCargo && <NuevoCargo setNewCargo={setNewCargo} />}
				</div>
				{/*Find de cuentadata*/}

				{/*Inicia facturas si el status es facturada*/}
				{facturas.length !== 0 && (
					<>
						<h3>Facturas de la cuenta</h3>
						<table id='car' className='w-100'>
							<thead>
								<tr>
									<th>Cuenta</th>
									<th>Fecha</th>
									<th>Razón Social</th>
									<th>Cliente</th>
									<th>Folio</th>
									<th>Folio Fiscal</th>
									<th>PDF</th>
									<th>XML</th>
									<th>Enviar</th>
								</tr>
							</thead>
							<tbody>
								{facturas.length !== 0 &&
									facturas.map((res) => <Descfactd key={res.id} factura={res} />)}
							</tbody>
						</table>
						<h3>Cargos de la Cuenta</h3>
					</>
				)}
				{/*Termina facturas*/}

				{/*Inicia tabla de cargos*/}
				<table id='tablaCargos' className='w-100'>
					<thead>
						<tr>
							<th>Hab.</th>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Concepto</th>
							<th>Metodo de Pago</th>
							<th>Importe</th>
							<th>Abono</th>
							<th>Referencia</th>
							<th>Comentario</th>
							<th>Cajero</th>
							<th>Folio</th>
							<th>Borrar</th>
						</tr>
					</thead>
					<tbody>
						{Array.isArray(conceptos)
							? conceptos.map((res) => <Cargostd key={res.id} cargo={res} />)
							: null}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan='6' />
							<th scope='row'>total</th>
							<td>
								<strong className='medium'>{moneyForm(subneto)}</strong>
							</td>
							<th scope='row'>Abonado</th>
							<td>
								<strong className='medium'>{moneyForm(abonado)}</strong>
							</td>
							<th scope='row'>saldo</th>
							<td>
								<strong className='medium'>{moneyForm(saldo)}</strong>
							</td>
						</tr>
					</tfoot>
				</table>
				{/*Finaliza tabla cargos*/}

				{/*Inciia lógica de cierre*/}
				<div className='w-100 flexRigth'>
					{/*cuenta.status !== "cancelada" &&
						(conceptos === "Cuenta sin cargos" || cancelable) && (
							<button
								onClick={cancelarFac}
								className='btn btn-dark cerrarCuentabtn my-1'
								style={{ marginRight: "10px", background: "red" }}
							>
								Cancelar Cuenta
							</button>
						)*/}
					<ReactToPrint
						trigger={() => (
							<button
								className='btn btn-dark cerrarCuentabtn my-1'
								style={{ marginRight: "10px" }}
							>
								Estado de cuenta
							</button>
						)}
						content={() => ref2p.current}
					/>
					{saldo > -0.009 &&
					saldo < 0.009 &&
					status === "abierta" &&
					conceptos !== "Cuenta sin cargos" &&
					!facMod ? (
						<button
							onClick={cerrarCuenta}
							className='btn btn-dark cerrarCuentabtn my-1'
						>
							Cerrar Cuenta
						</button>
					) : null}
				</div>
				{/*Temina lógica de cierre*/}
				<div style={{ display: "none" }}>
					<div ref={ref2p}>
						<Estadodecuenta />
					</div>
				</div>
			</div>
			<Modal
				open={facMod}
				facModCerrar={() => {
					setFactMod(false);
				}}
			>
				<Facturar
					drill={() => {
						drill(false);
					}}
				/>
			</Modal>
			{/*Termina cargosMainWrapp*/}
		</>
	);
};

export default Cargosth;
