import React, { useContext } from "react";
import PropTypes from "prop-types";

const Abonostdlight = ({
	abono: {
		folio,
		modo_pago,
		factura,
		suma,
		cajero,
		nombre,
		fecha,
		hab,
		abonos,
		status,
		alcierre,
		efectivo,
		tarjetas,
		debito,
	},
}) => {
	let hoy = new Date();
	let eshoy = false;
	if (fecha.substring(0, 10) === hoy.toISOString().split("T")[0]) {
		eshoy = true;
	}

	let diames = "";
	if (fecha !== "sin fechas") {
		const fechaS = fecha.split(" ");
		const llegadaF = fechaS[0].split("-");
		const meses = [
			"dud",
			"Enero",
			"Feb",
			"Mar",
			"Abril",
			"Mayo",
			"Jun",
			"Jul",
			"Ago",
			"Sep",
			"Oct",
			"Nov",
			"Dic",
		];
		diames = llegadaF[2] + "/" + meses[llegadaF[1].replace(/^0+/, "")];
	}
	let tieneabono = false;
	let totalabono = 0;
	let abonohoy = false;
	let metodo = "";
	let cxcx = 0;
	function subtractHours(date, hours) {
		date.setHours(date.getHours() - hours);
		return date;
	}
	if (typeof abonos !== "undefined") {
		abonos.map((r) => {
			if (r.metodo_pago === "Reembolso") {
				tieneabono = true;
			} else {
				totalabono = totalabono + parseFloat(r.abono);
			}
			if (r.metodo_pago === "cuentas por cobrar") {
				cxcx = cxcx + parseFloat(r.abono);
			}
			let dprevio = new Date(r.fecha);
			let fprevio = subtractHours(dprevio, 1);
			if (
				r.fecha.substring(0, 13) === fecha.substring(0, 13) ||
				r.fecha.substring(0, 13) ===
					fprevio.toISOString().substring(0, 13).replace("T", " ")
			) {
				abonohoy = true;
				metodo = r.metodo_pago;
			}
		});
	}

	//////////////////////////////////
	// rembolsos
	const rembo = (abonos) => {
		let rembosum = 0;
		if (typeof abonos !== "undefined") {
			abonos.map((row) => {
				if (row.metodo_pago === "Reembolso" && row.status === 0) {
					rembosum = parseFloat(rembosum) + parseFloat(row.abono);
				}
			});
		}
		if (rembosum !== 0) {
			return Math.abs(rembosum).toFixed(2);
		} else {
			return 0;
		}
	};
	////////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		let numero = parseFloat(raw);
		if (numero < 0) numero = Math.abs(numero);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};
	return (
		<>
			{/*aborow(abonos)*/}
			<tr className={factura !== "" ? null : status == 1 ? "cancelada" : null}>
				<td>{factura}</td>
				<td>{folio}</td>
				<td>{fecha !== "sin fechas" ? diames : fecha}</td>
				<td>{hab}</td>
				<td>{nombre}</td>
				<td>{factura !== "" && moneyForm(suma)}</td>
				<td>
					{modo_pago === "Efectivo" && factura === "" && moneyForm(suma)}
					{factura !== "" && alcierre && efectivo > 0 && moneyForm(efectivo)}
				</td>
				<td>
					{(modo_pago == "Tarjeta de Credito" || modo_pago == "Tarjeta de Debito") &&
					factura === ""
						? moneyForm(suma)
						: factura !== "" && modo_pago == "TCredito" && tarjetas > 0
						? moneyForm(tarjetas)
						: null}
					{factura !== "" && alcierre && tarjetas > 0 ? moneyForm(tarjetas) : null}
				</td>
				<td>
					{/*modo_pago === "cuentas por cobrar" && factura === "" && moneyForm(suma)*/}
					{modo_pago === "CXC" && factura !== "" && moneyForm(suma)}
				</td>
				<td>
					{factura !== "" && rembo(abonos) > 0 && <strong>${rembo(abonos)}</strong>}
					{factura === "" && suma < 0 && <strong>${Math.abs(suma)}</strong>}
				</td>
				<td>
					{factura !== "" &&
						modo_pago !== "CXC" &&
						modo_pago !== "cuentas por cobrar" &&
						!tieneabono &&
						!abonohoy &&
						debito <= 0 &&
						moneyForm(suma - cxcx)}
					{debito > 0 && moneyForm(debito)}
				</td>
				<td
					className={factura == "" && modo_pago === "transferencia" ? "grey" : ""}
				>
					{factura == "" &&
						(modo_pago === "Efectivo" ||
							modo_pago === "Tarjeta de Credito" ||
							modo_pago === "Tarjeta de Debito") &&
						moneyForm(suma)}
					{factura == "" && modo_pago === "Transferencia" && (
						<p className='mini grey'>DEPRE X</p>
					)}
					{factura == "" && modo_pago === "Transferencia" && (
						<p className='grey'>{moneyForm(suma)}</p>
					)}
				</td>
				<td>{cajero}</td>
			</tr>
		</>
	);
};

Abonostdlight.propTypes = {
	abono: PropTypes.object.isRequired,
};
export default Abonostdlight;
