import React, { useContext, useState, useEffect } from "react";
import CargoContext from "../../contexto/cargo/cargoContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import AlertContext from "../../contexto/alerta/alertContext";

const Busquedarep = ({ fechaData, setTurnoPadre, setShowBtn }) => {
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	const cargoContext = useContext(CargoContext);
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const facturacionContext = useContext(FacturacionContext);
	const [qfecha, setQfecha] = useState(hoy);
	const [qturno, setQturno] = useState("Dia");
	const [busqueda, setBusqueda] = useState(false);
	const onChangeQFecha = (e) => setQfecha(e.target.value);
	const onChangeQTurno = (e) => setQturno(e.target.value);
	const { traerFacturasByFechas, facturas } = facturacionContext;
	const {
		cargos,
		traerTurnoUltimo,
		traerCargosByFechas,
		traerAbonosByFecha,
		traerTurnosByDia,
		traerTurnoByDia,
	} = cargoContext;

	const [turno, setTurno] = useState({});
	///////////////////////////////
	// buscar
	const buscarRango = async () => {
		if (qturno === "Dia") {
			//si es todo el dia traes los turnos
			fechaData(qfecha);
			const resp = await traerTurnosByDia(qfecha);
			//sio no encuentra reportas
			!resp.data.success && setAlert(resp.data.messages, "danger");
			//si llegan consultas
			if (resp.success) {
				setAlert("Rango encontrado", "success");
				setBusqueda(true);
				setShowBtn(true);
				setTurnoPadre("");
				const abonos = await traerAbonosByFecha(
					qfecha + "T00:00:01",
					qfecha + "T23:59:59"
				);
				const transacciones = await traerCargosByFechas(
					resp.data.inicio,
					resp.data.final
				);
				const facturas = await traerFacturasByFechas(
					resp.data.inicio,
					resp.data.final
				);
			}
		} else {
			const resp = await traerTurnoByDia(qturno, qfecha);

			if (resp.success) {
				if (resp.data[0].final !== "0000-00-00 00:00:00") {
					setBusqueda(true);
					setShowBtn(true);
					setAlert("turno encontrado", "success");
					let inicio = resp.data[0].inicio;
					let final = resp.data[0].final;
					const a = await traerAbonosByFecha(inicio, final);
					console.log(a);
					const b = await traerCargosByFechas(inicio, final);
					console.log(b);
					const c = await traerFacturasByFechas(inicio, final);
					console.log(c);
					let turnis = typeof qturno !== "undefined" ? qturno : "";
					setTurnoPadre(turnis);
					fechaData(final.substring(0, 10));
				} else {
					traer();
				}
			} else {
				setAlert(resp.data.messages[0], "danger");
			}
		}
	};
	///////////////////////////////
	//traer
	const traer = async () => {
		setBusqueda(false);
		setShowBtn(false);
		//trae el turno en curso
		const resp = await traerTurnoUltimo();
		//convertimos el ts del inicio del turno en una fecha para busqueda
		let turnoEnCurso = resp.data[0];
		setTurno(turnoEnCurso);
		let diaEnCurso = resp.data[0].fechaadm;
		let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		const date1 = new Date(resp.data[0].inicio);
		let inicio = new Date(date1 - tzoffset).toISOString().slice(0, 19);
		// creamos un ts actual y le damos formato para busqueda
		let cierre = new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
		//definimos inicio y cierre del inicio del turno hasta ahora
		const abonos = await traerAbonosByFecha(inicio, cierre);
		const turnos = await traerTurnosByDia(resp.data[0].fechaadm);
		const transacciones = await traerCargosByFechas(
			turnos.data.inicio,
			turnos.data.final
		);
		const facturas = await traerFacturasByFechas(
			turnos.data.inicio,
			turnos.data.final
		);
	};
	///////////////////////////////
	// useEffect
	useEffect(() => {
		if (cargos.length === 0) {
			traer();
		}
	}, [qfecha, qturno, busqueda]);
	return (
		<>
			<div>
				{turno !== {} ? (
					<div className='all-center-row'>
						<p className='Lead'>
							Turno en Curso: <strong>{turno.turno}</strong>
						</p>
						<p className='Lead'>
							Inicio de turno: <strong>{turno.inicio}</strong>
						</p>
						<p className='Lead'>
							Fecha: <strong>{turno.fechaadm}</strong>
						</p>
					</div>
				) : null}
			</div>
			<section className='grid-4 mb-1 noprint' id='busqueda'>
				<article>
					<label htmlFor='turnos'>Turno</label>
					<select name='turnos' id='' onChange={onChangeQTurno}>
						<option value='Dia'>Dia</option>
						<option value='1'>Turno 1</option>
						<option value='2'>Turno 2</option>
						<option value='3'>Turno 3</option>
					</select>
				</article>
				<article>
					<label htmlFor='llegada'>Fecha</label>
					<input
						name='llegada'
						type='date'
						value={qfecha}
						onChange={onChangeQFecha}
						min='2000-01-01'
						max={hoy}
						className=''
						required
					/>
				</article>
				<article>
					<button onClick={buscarRango} className='btn btn-dark w-100 mt-2'>
						Buscar
					</button>
				</article>
				<article>
					{busqueda && (
						<button onClick={traer} className='btn btn-dark w-100 mt-2'>
							Turno en curso
						</button>
					)}
				</article>
			</section>
		</>
	);
};

export default Busquedarep;
