import React, { useContext, useEffect, useState } from "react";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import Repofactmestd from "./Repofactmestd.js";

const Repofactmesth = () => {
	const [fechap, setFechap] = useState("");
	const facturacionContext = useContext(FacturacionContext);
	const { facturas } = facturacionContext;
	console.log(facturas);
	const [seriesel, setSeriesel] = useState("A");
	let serie = [];

	if (Array.isArray(facturas) && facturas.length > 0) {
		serie = facturas.filter((factura) => factura.serie === seriesel);
	}
	let dummie = [
		{
			id: 0,
			serie: "A",
			folio: "0",
			c_id: "0",
			e_id: "0",
			forma_pago: "sin pagos",
			importe: 0,
			iva: 0,
			ieh: 0,
			total: 0,
			fecha: "2020-01-01",
			usr: "sin dato",
			rfc: "sin dato",
			rsocial: "sin dato",
			conceptos: [
				{
					concepto: "habitacion",
					importe: "0",
				},
			],
		},
	];
	const meses = [
		"dud",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let mes = "";
	let gsubtotal = 0;
	let giva = 0;
	let gieh = 0;
	let gtotal = 0;
	let thospedaje = 0;
	let tcafeteria = 0;
	let tlavanderia = 0;
	let tbar = 0;
	let tpropina = 0;
	let tcnegocios = 0;
	if (Array.isArray(facturas) && facturas.length > 0) {
		serie.map((row, i) => {
			gsubtotal = gsubtotal + row.importe;
			giva = giva + row.iva;
			gieh = gieh + row.ieh;
			gtotal = gtotal + row.total;
			row.conceptos.map((row2) => {
				if (row2.status === 0) {
					switch (row2.concepto) {
						case "habitacion":
							thospedaje = thospedaje + parseFloat(row2.importe);
							break;
						case "Consumo":
							tcafeteria = tcafeteria + parseFloat(row2.importe);
							break;
						case "cafeteria":
							tcafeteria = tcafeteria + parseFloat(row2.importe);
							break;
						case "lavanderia":
							tlavanderia = tlavanderia + parseFloat(row2.importe);
							break;
						case "bar":
							tbar = tbar + parseFloat(row2.importe);
							break;
						case "propina":
							tpropina = tpropina + parseFloat(row2.importe);
							break;
						case "CentroNegocios":
							tcnegocios = tcnegocios + parseFloat(row2.importe);
							break;
					}
				}
			});
		});
	}

	//////////////////////////////
	//formato de numero
	const moneyForm = (raw) => {
		const numero = parseFloat(raw);
		if (!isNaN(numero)) {
			const res = "$" + numero.toFixed(2);
			return res;
		} else {
			return "Sin Cargos";
		}
	};

	////////////////////////////////
	//serieselect
	const serieselect = (e) => {
		setSeriesel(e.target.value);
	};
	useEffect(() => {
		if (serie.length > 0) {
			if (
				serie.every((v) => v.fecha.slice(0, 10) === serie[0].fecha.slice(0, 10))
			) {
				let diaf = serie[0].fecha.slice(0, 10);
				let dia = diaf.split("-");
				let mes = meses[parseInt(dia[1])];
				setFechap(dia[2] + "/" + mes + "/" + dia[0]);
			} else {
				let rfecha = serie[0].fecha.slice(0, 10);
				let mesi = rfecha.split("-");
				let mes = meses[parseInt(mesi[1])];
				setFechap(mes);
			}
		}
	}, [serie]);
	return (
		<div className='mb-2'>
			<div className='grid-2'>
				<h2>Reporte de facturas de: {fechap}</h2>
				<select onChange={serieselect}>
					<option value='A'>Serie A</option>
					<option value='B'>Serie B</option>
					<option value='C'>Serie C</option>
					<option value='D'>Serie D</option>
				</select>
			</div>
			<table className='w-100 tightable printabla'>
				<thead className='stickiehead'>
					<th>Factura</th>
					<th>RFC</th>
					<th>R.Social</th>
					<th>Fecha</th>
					<th>Hospedaje</th>
					<th>Cafetería</th>
					<th>Lavandería</th>
					<th>Bar</th>
					<th>Propina</th>
					<th>C.Neg.</th>
					<th>Sub.T.</th>
					<th>IVA</th>
					<th>3%</th>
					<th>Total</th>
				</thead>
				{Array.isArray(facturas) && facturas.length === 0
					? dummie.map((res) => <Repofactmestd key={res.folio} factura={res} />)
					: serie.map((res, i) => <Repofactmestd key={i} factura={res} />)}
				<tr>
					<th colSpan={4} scope='row'>
						Totales:
					</th>
					<th scope='row'>Hosp.</th>
					<th scope='row'>Cafe.</th>
					<th scope='row'>Lav.</th>
					<th scope='row'>bar</th>
					<th scope='row'>prop.</th>
					<th scope='row'>C.Neg.</th>
					<th scope='row'>Sub.T.</th>
					<th scope='row'>IVA</th>
					<th scope='row'>IEH</th>
					<th scope='row'>Total</th>
				</tr>
				<tr>
					<td colSpan={4} />
					<td>
						<strong>{moneyForm(thospedaje)}</strong>
					</td>
					<td>
						<strong>{moneyForm(tcafeteria)}</strong>
					</td>
					<td>
						<strong>{moneyForm(tlavanderia)}</strong>
					</td>
					<td>
						<strong>{moneyForm(tbar)}</strong>
					</td>
					<td>
						<strong>{moneyForm(tpropina)}</strong>
					</td>
					<td>
						<strong>{moneyForm(tcnegocios)}</strong>
					</td>
					<td>
						<strong>{moneyForm(gsubtotal)}</strong>
					</td>
					<td>
						<strong>{moneyForm(giva)}</strong>
					</td>
					<td>
						<strong>{moneyForm(gieh)}</strong>
					</td>
					<td>
						<strong>{moneyForm(gtotal)}</strong>
					</td>
				</tr>
			</table>
		</div>
	);
};

export default Repofactmesth;
