import React, { useContext, useState, useEffect } from "react";
import ReservaContext from "../../contexto/reservacion/reservaContext.js";
import AlertContext from "../../contexto/alerta/alertContext.js";
import CuentaContext from "../../contexto/cuenta/cuentaContext.js";
import RegistroContext from "../../contexto/registro/registroContext.js";
import FacturacionContext from "../../contexto/facturacion/facturacionContext.js";
import tarifas from "../../assets/tarifas";
import Rfcpicker from "../facturacion/Rfcpicker.js";
import Prompt from "../../assets/Prompt";
import Regarr from "./Regarr.js";

const RegistroDirecto = ({ togg }) => {
	const hoyrw = new Date();
	const hoyr = new Date(hoyrw.getTime() - hoyrw.getTimezoneOffset() * 60000);
	const hoy = hoyr.toISOString().split("T")[0];
	const mananarw = new Date();
	const mananar = new Date(mananarw.setDate(mananarw.getDate() + 1));
	const manana = mananar.toISOString().split("T")[0];
	////////////////////
	//contexto
	const reservaContext = useContext(ReservaContext);
	const {
		nuevaReserva,
		nuevoCliente,
		traerTarifas,
		reservaChkId,
		reservacion,
		traerReservaSingle,
	} = reservaContext;
	const facturacionContext = useContext(FacturacionContext);
	const { empresa, limpiarEmpresa, traerPublicoId } = facturacionContext;
	const cuentaContext = useContext(CuentaContext);
	const { crearCuenta, limpiarCuentas } = cuentaContext;
	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;
	const registroContext = useContext(RegistroContext);
	const { crearRegistro, unSetRegistros, habitaciones, traerVaciasL } =
		registroContext;
	const tarsolo = tarifas.map((r) => {
		return r.Nombre;
	});
	const habsolo = habitaciones.map((row) => {
		if (row.status === "vaciaL" || row.status === "asignadaL") {
			return parseInt(row.habitacion);
		}
	});

	///////////////////////////////////////
	const [prompt, setPrompt] = useState(false);
	const prompteador = (ev) => {
		ev.preventDefault();
		setPrompt(true);
	};
	///////////////////
	//state
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");
	const [grupo, setGrupo] = useState("");
	const [llegada, setLlegada] = useState(hoy);
	const [hora, setHora] = useState("11:30");
	const [salida, setSalida] = useState(manana);
	const [tarifa, setTarifa] = useState("sencilla");
	const [personas, setPersonas] = useState(1);
	const [sHabitaciones, setsHabitaciones] = useState(1);
	const [info, setInfo] = useState("");
	const [ref, setRef] = useState("");
	const [factura, setFactura] = useState(false); // tooggle clientes
	const [cliente_id, setCliente_id] = useState(null);
	const [empresa_id, setEmpresa_id] = useState(null);
	const [credito, setCred] = useState(0);
	const [empresaCheck, setEmpresaCheck] = useState(null);
	const [folio, setFolio] = useState(0);
	const [folchk, setFolchk] = useState(false);
	const [usr, setUsr] = useState("");
	const dialogo = document.getElementById("diag");
	const [mpago, setMpago] = useState("Efectivo");
	const [habsArr, setHabsArr] = useState([
		{
			habitacion: 0,
			tarifa: "NOCD1",
			titular: "titular",
			ciudad: "",
			estado: "",
		},
	]);
	///////////////////
	//onChange inputs
	const onChangeNombre = (e) => setNombre(e.target.value);
	const onChangeApellido = (e) => setApellido(e.target.value);
	const onChangeGrupo = (e) => setGrupo(e.target.value);
	const onChangeLlegada = (e) => setLlegada(e.target.value);
	const onChangeSalida = (e) => setSalida(e.target.value);
	const onChangeTarifa = (e) => setTarifa(e.target.value);
	const onChangePersonas = (e) => setPersonas(e.target.value);
	const onChangeInfo = (e) => setInfo(e.target.value);
	const onChangeRef = (e) => setRef(e.target.value);
	const onChangeCred = (e) => setCred(e.target.value);
	const onChangeMpago = (e) => setMpago(e.target.value);
	const onChangeFactura = (e) =>
		e.target.checked ? setFactura(true) : setFactura(false); // toogle clienteszzz
	const onChangeUsr = (e) => setUsr(e.target.value);
	const OCnnC = (e) => {
		if (e.target.checked) {
			setFolchk(true);
		} else {
			setFolchk(false);
			setFolio(0);
		}
	};
	const onChangeFolio = (e) => {
		setFolio(e.target.value);
		let stripInfo = "";
		let habsarrtemp = new Array();
		const resis = reservaChkId(e.target.value);
		resis
			.then((r1) => {
				let titular = r1.titular;
				let reserva = traerReservaSingle(r1.id);
				reserva.then((r) => {
					console.log(r);

					setHabsArr([]);
					if (
						typeof r.data.info !== "undefined" &&
						r.data.info !== "" &&
						r.data.info !== null
					) {
						if (r.data.info.includes("\n")) {
							let arrInfo = r.data.info.split("\n");
							if (arrInfo.length > 1) {
								stripInfo = arrInfo[1];
							} else {
								stripInfo = "";
							}
						}
					}
					r.data.tarifas.length > 6
						? setsHabitaciones(r.data.tarifas.trim().split(" ").length)
						: setsHabitaciones(1);
					const tarifasArr = r.data.tarifas.trim().split(" ");
					tarifasArr.map((r) => {
						console.log(r);
						habsarrtemp.push({
							habitacion: 0,
							tarifa: r,
							titular: titular,
							ciudad: "ciudad",
							estado: "Estado",
						});
					});
					setHabsArr(habsarrtemp);
					setNombre(r.data.nombre);
					setApellido(r.data.apellido);
					setGrupo(r.data.grupo);
					setSalida(r.data.salida);
					setPersonas(r.data.personas);
					setInfo(stripInfo);
					r.data.ref !== null ? setRef(r.data.ref) : setRef("");
				});
			})
			.catch((r) => {
				console.log(r.data);
			});
	};
	///////////////////////
	// metodo para crear el body del cliente
	const getClienteData = () => {
		const clienteObj = {};
		clienteObj.nombre = nombre;
		clienteObj.apellido = apellido;
		grupo.length === 0 ? (clienteObj.grupo = null) : (clienteObj.grupo = grupo);
		return clienteObj;
	};

	///////////////////////
	// metodo para crear el body de la reserva
	const getReservaData = () => {
		const reservaObj = {};
		reservaObj.llegada = llegada;
		reservaObj.hora = hora;
		reservaObj.salida = salida;
		reservaObj.tarifa = tarifa;
		reservaObj.personas = personas;
		reservaObj.sHabitaciones = sHabitaciones;
		reservaObj.grupo = grupo;
		info.length > 0 && (reservaObj.info = info);
		console.log(typeof ref);
		console.log(typeof info);
		ref.length > 0 && (reservaObj.ref = ref);
		reservaObj.cliente_id = cliente_id;
		reservaObj.empresa_id = empresa_id;
		reservaObj.mpago = mpago;
		reservaObj.reservo = usr;
		setUsr(null);
		return reservaObj;
	};

	////////////////////////
	//metodo para crear el cuerpo de la cuenta
	const getCuentaData = () => {
		const cuentaObj = {};
		cuentaObj.cliente_id = "";
		cuentaObj.empresa_id = "";
		cuentaObj.reserva_id = "";
		if (credito > 0) {
			cuentaObj.credito = credito;
		}
		return cuentaObj;
	};

	////////////////////////////////////
	// contructor del body de registros
	const getRegistroData = () => {
		const registroObj = { registros: [] };
		for (let i = 0; i < sHabitaciones; i++) {
			let row = {
				cliente_id: 0,
				reserva_id: 0,
				cuenta_id: 0,
				empresa_id: null,
				titular: nombre,
				habitacion: habsArr[i].habitacion,
				tarifa: habsArr[i].tarifa,
				registro: "",
			};
			registroObj.registros.push(row);
		}
		return registroObj;
	};

	////////////////////////////
	// métodos
	const submitRregistro = async (usr) => {
		setUsr(usr);
		traerVaciasL();
		////////////////////////
		habsArr.forEach((row) => {
			//valida disponibilidad de habitacion
			let check = habsolo.includes(parseInt(row.habitacion));
			let check2 = tarsolo.includes(row.tarifa); //valida que la tarifa este bien escrita
			if (!check) {
				setAlert(
					"la habitacion " + row.habitacion + " no esta disponible, verifique",
					"danger"
				);
				setUsr("");
				dialogo.close();
				throw new Error("fallo");
			}
			if (!check2) {
				setAlert(
					"la Tarifa " + row.tarifa + " tiene un error, verifique",
					"danger"
				);
				setUsr("");
				dialogo.close();
				throw new Error("fallo");
			}
		});
		//////////////////////
		const publico = await traerPublicoId();
		limpiarEmpresa();
		limpiarCuentas();
		let registrante = usr;
		///inicializa datos para reserva

		const clienteData = getClienteData();
		const reservaData = getReservaData();
		const cuentaData = getCuentaData();
		const registroData = getRegistroData();
		cuentaData.aperturo = registrante;
		reservaData.reservo = registrante;
		registroData.registro = registrante;
		/////condicional si se proporciona folio
		if (folchk === true && folio > 0) {
			console.log(folio);
			const resData = reservaChkId(folio);
			resData
				.then((data) => {
					cuentaData.reserva_id = data.id;
					cuentaData.cliente_id = data.cliente_id;
					cuentaData.empresa_id =
						data.empresa_id !== null ? data.empresa_id : publico;
					let titular = data.titular;
					const cuentaResp = crearCuenta(cuentaData);
					cuentaResp
						.then((r) => {
							console.log(r);
							for (let i = 0; i < registroData.registros.length; i++) {
								registroData.registros[i].reserva_id = r.data.reserva_id;
								registroData.registros[i].cliente_id = r.data.cliente_id;
								registroData.registros[i].empresa_id =
									data.empresa_id !== null ? r.data.empresa_id : publico;
								registroData.registros[i].cuenta_id = r.data.id;
								//registroData.registros[i].titular = titular;
								registroData.registros[i].registro = registrante;
							}
							const registroResp = crearRegistro(registroData);
							registroResp
								.then((r) => {
									setAlert("los regístros se crearon existosamente", "success");
									setUsr("");
									unSetRegistros();
									togg();
								})
								.catch((er) => {
									setAlert("error en el registro", "danger");
									setUsr("");
								});
						})
						.catch((er) => {
							setAlert("fallo al crear la cuenta", "danger");
							setUsr("");
						});
				})
				.catch((er) => {
					setFolio("");
					setAlert(
						"El Folio que ingresó es inválido o ya está registrado favor de verificar",
						"danger"
					);
				});
		} else {
			///si no se proporciono folio se procesa completo
			const clienteResp = await nuevoCliente(clienteData);
			if (factura && empresaCheck) {
				setEmpresa_id(empresa.id);
				reservaData.empresa_id = empresa.id;
				cuentaData.empresa_id = empresa.id;
				for (let i = 0; i < registroData.registros.length; i++) {
					registroData.registros[i].empresa_id = empresa.id;
				}
			} else {
				setEmpresa_id(publico);
				reservaData.empresa_id = publico;
				cuentaData.empresa_id = publico;
				for (let i = 0; i < registroData.registros.length; i++) {
					registroData.registros[i].empresa_id = publico;
				}
			}
			if (clienteResp.success) {
				setCliente_id(clienteResp.data.id);
				reservaData.cliente_id = clienteResp.data.id;
				cuentaData.cliente_id = clienteResp.data.id;
				for (let i = 0; i < registroData.registros.length; i++) {
					registroData.registros[i].cliente_id = clienteResp.data.id;
					registroData.registros[i].registro = usr;
				}
			} else {
				setAlert(clienteResp.messages[0], "danger");
				return;
			}
			const reservaResp = await nuevaReserva(reservaData);
			console.log(reservaResp);

			if (reservaResp.success) {
				cuentaData.reserva_id = reservaResp.data.id;
				for (let i = 0; i < registroData.registros.length; i++) {
					registroData.registros[i].reserva_id = reservaResp.data.id;
				}
			} else {
				setAlert(reservaResp.messages, "danger");
			}

			const cuentaResp = await crearCuenta(cuentaData);
			console.log(cuentaResp);

			if (cuentaResp.success) {
				for (let i = 0; i < registroData.registros.length; i++) {
					registroData.registros[i].cuenta_id = cuentaResp.data.id;
				}
			} else {
				setAlert(cuentaResp.messages[0], "danger");
			}
			const registroResp = await crearRegistro(registroData);
			if (registroResp.success) {
				setAlert("los regístros se crearon existosamente", "success");
				togg();
			} else {
				setAlert(registroResp.messages[0], "danger");
			}
		}
	};

	//////////////////
	//useEffect
	useEffect(() => {
		if (tarifas.length === 0) {
			traerTarifas();
		}
		if (habitaciones.length === 0) traerVaciasL();
		//si submiteas usr se vuelve true
		if (sHabitaciones > 0) {
			let habsArrL = habsArr.length;
			if (habsArrL < sHabitaciones) {
			}
		}
		if (Object.keys(empresa).length !== 0) {
			setEmpresaCheck(true);
		}
		if (reservacion.length > 0) {
			console.log(reservacion);
		}

		//eslint-disable-next-line
	}, [usr, tarifas, sHabitaciones, empresa, habsArr]);

	////////////////////////////////////////////////////////////////////////////
	//return salida return salida return salida
	return (
		<>
			<Prompt
				onClose={setPrompt}
				show={prompt}
				setKey={setUsr}
				onValidKey={submitRregistro}
			/>
			<div id='nuevaReserva'>
				<form action='' onSubmit={prompteador} className='card'>
					<section className='grid-5'>
						{/*Columna Uno*/}
						<article className='columna gspan-3'>
							{/*Campo nombre*/}
							<label htmlFor='nombre'>Nombre</label>
							<input
								name='nombre'
								type='text'
								value={nombre}
								onChange={onChangeNombre}
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo apellido*/}
							<label htmlFor='apellido'>Apellido</label>
							<input
								name='apellido'
								type='text'
								value={apellido}
								onChange={onChangeApellido}
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							<label htmlFor='grupo'>Grupo</label>
							<input
								name='grupo'
								type='text'
								value={grupo}
								onChange={onChangeGrupo}
								className=''
								disabled={!folchk ? false : true}
							/>
						</article>

						{/*Columna Dos*/}
						<article className='columna'>
							{/*Campo llegada*/}
							<label htmlFor='llegada'>Llegada</label>
							<input
								name='llegada'
								type='date'
								value={llegada}
								onChange={onChangeLlegada}
								min={hoy}
								max='2122-01-01'
								className=''
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo salida*/}
							<label htmlFor='salida'>Salida</label>
							<input
								name='salida'
								type='date'
								value={salida}
								onChange={onChangeSalida}
								className=''
								min={hoy}
								max={"2123-01-01"}
								required={folchk ? false : true}
								disabled={!folchk ? false : true}
							/>
							{/*Campo personas*/}
							<label htmlFor='personas'>No de personas</label>
							<input
								type='number'
								name='personas'
								value={personas}
								onChange={onChangePersonas}
								min='1'
								max='300'
								disabled={!folchk ? false : true}
							/>
						</article>
						{/*Columna tress*/}
						<article className='columna'>
							{/*Campo personas*/}
							<input type='checkbox' onChange={OCnnC} />
							<label htmlFor='folio'>
								{" "}
								Folio{" "}
								<span className='mini' style={{ color: "grey" }}>
									{"(Si cuenta con reservación)"}
								</span>
							</label>
							<input
								type='number'
								name='folio'
								value={folio}
								min='1'
								onChange={onChangeFolio}
								disabled={folchk ? false : true}
								required={folchk ? true : false}
							/>
							<label htmlFor='credito'>Credito</label>
							<input
								name='credito'
								type='number'
								value={credito}
								onChange={onChangeCred}
								className=''
								disabled={!folchk ? false : true}
							/>
							<label htmlFor='mpago'>Metodo de pago</label>
							<select name='mpago' onChange={onChangeMpago}>
								<option value='Efectivo'>Efectivo</option>
								<option value='Tarjeta de Credito'>T Credito</option>
								<option value='Tarjeta de Debito'>T Debito</option>
								<option value='Transferencia'>Transferencia</option>
								<option value='Cheque'>Cheque</option>
								<option value='Cuentas por cobrar'>CXC</option>
							</select>
						</article>
					</section>
					<section>
						<Regarr
							setHabsArr={setHabsArr}
							habsArr={habsArr}
							sHabitaciones={sHabitaciones}
							setsHabitaciones={setsHabitaciones}
						/>
					</section>
					<section className='my-1'>
						<article>
							<input
								type='checkbox'
								value='1'
								name='factura'
								onChange={onChangeFactura}
							/>
							<label htmlFor='factura' className='m-1'>
								Asignar empresa{" "}
								{empresaCheck !== null && (
									<i className='fas fa-check-circle paloma'></i>
								)}
							</label>
						</article>
					</section>
					{factura ? <Rfcpicker /> : null}
					<section className='grid-2'>
						<div>
							<label htmlFor='info'>Comentarios</label>
							<textarea
								name='info'
								id=''
								rows='3'
								value={info}
								onChange={onChangeInfo}
								disabled={!folchk ? false : true}
							></textarea>
						</div>
						<div>
							<label htmlFor='ref'>Referencia</label>
							<textarea
								name='ref'
								id=''
								rows='3'
								value={ref}
								onChange={onChangeRef}
								disabled={!folchk ? false : true}
							></textarea>
						</div>
						<input
							type='submit'
							className='btn btn-dark btn-block my gspan-2'
							value='Crear Registro'
						/>
					</section>
				</form>
			</div>
		</>
	);
};

export default RegistroDirecto;
